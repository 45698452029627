export function initDropdowns() {
  $(document).ready(function() {
    var dropdowns = $(".menu-item--dropdown");
    dropdowns.click(function() {
      $(this).toggleClass("menu-item--dropdown--open");
    });
  });
  closeDropdownsOnMouseLeave();
}

export function toggleHamburger() {
  $(document).ready(function() {
    var hamburger = $(".network-menu__hamburger");
    hamburger.click(function() {
      closeDropdowns();
      $(this)
        .find(".hamburger")
        .toggleClass("hamburger--open");
    });
  });
}

export function closeDropdowns() {
  $(document).ready(function() {
    var dropdowns = $(".menu-item--dropdown");
    dropdowns.removeClass("menu-item--dropdown--open");
  });
}

export function closeDropdownsOnMouseLeave() {
  $(document).ready(function() {
    var dropdown = $(".dropdown");
    var dropdowns = $(".menu-item--dropdown");
    dropdown.mouseleave(function() {
      dropdowns.removeClass("menu-item--dropdown--open");
    });
  });
}

// network menu

export function toggleNetworkModal() {
  $(document).ready(function() {
    var hamburger = $(".network-menu__hamburger");
    var modal = $(".mega-menu");
    var networkMenu = $(".network-menu");

    hamburger.click(function() {

      // if ipad dont use slideToggle
      // it will change the size of the modal, making it too big for the screen
      if (window.innerWidth !== 768 && window.innerWidth !== 1024 && window.innerHeight > 700) {
        modal.slideToggle();
        toggleScrollBlockOnBody();
      }

      modal.toggleClass("mega-menu--visible");
      networkMenu.toggleClass("network-menu--modal");
    });
    $("body").on("click", function(e) {
      if (e.target !== this) return;
      closeNetworkModal();
    });
  });
}

export function closeNetworkModal() {
  $(document).ready(function() {
    var hamburger = $(".network-menu__hamburger");
    var modal = $(".mega-menu");
    var networkMenu = $(".network-menu");
    modal.slideUp();
    modal.removeClass("mega-menu--visible");
    networkMenu.removeClass("network-menu--modal");
    hamburger.find('.hamburger').removeClass("hamburger--open");
    removeScrollBlockOnBody();
  });
}

function preserveNetworkMenuHeight(networkMenu) {
  var spaceFromTop = networkMenu.offset().top;

  // if already set, unset
  if (networkMenu.css("top") === "0px") {
    networkMenu.css("top", spaceFromTop);
  } else {
    networkMenu.css("top", 0);
  }
}

export function toggleScrollBlockOnBody() {
  $("body").toggleClass("body--block-scroll");
}

export function removeScrollBlockOnBody() {
  $("body").removeClass("body--block-scroll");
}

// mobile

export function openMobileNavigation() {
  $(document).ready(function() {
    var hamburger = $(".mobile-menu .hamburger");
    var mobileNavigation = $(".mobile-navigation-container");

    hamburger.click(function() {
      mobileNavigation.toggleClass("mobile-navigation--open");
      toggleScrollBlockOnBody();
    });
  });
}

export function openMobileAccordions() {
  $(document).ready(function() {
    var accordions = $(".accordion");

    if(accordions.length === 0) {
      return;
    }

    // if an accordion starts with the class accordion--open it should open
    for (var i = 0; i < accordions.length; i++){
      var accordion = accordions[i];
      if ($(accordion).hasClass('accordion--open')){
        $(accordion).children(".accordion__dropdown").slideDown();
      }
    }

    accordions.find('.accordion__header').click(function() {
      var accordion = $(this).parent(".accordion");
      closeMobileAccordions(accordion);
      accordion.toggleClass("accordion--open");
      accordion
        .children(".accordion__dropdown")
        .slideToggle();
    });
  });
}

function closeMobileAccordions(currentAccordion) {
  // if the accordion is open dont do anything
  if ($(currentAccordion).hasClass("accordion--open")) {
    return;
  }

  var accordions = $(".accordion");

  // if accordion is closed, first close the others
  for (var i = 0; i < accordions.length; i++) {
    var element = accordions[i];
    $(element).removeClass("accordion--open");
    $(element)
      .children(".accordion__dropdown")
      .slideUp();
  }
}

export function toggleSearchBar() {
  $(document).ready(function() {
    var editionMenu = $(".edition-menu");
    var openTrigger = $(".menu-section--search");
    var closeTrigger = $(".menu-item__icon--close");
    var searchInput = $("input[type=search]");

    openTrigger.click(function() {
      editionMenu.addClass("edition-menu--show-search-bar");
      focusInput(searchInput);
    });

    closeTrigger.click(function() {
      editionMenu.removeClass("edition-menu--show-search-bar");
    });
  });
}

function focusInput(input) {
  input.focus();
}

export function resizeTrendingTopics() {
  $(document).ready(function() {
    var menu = $(".menu-sections");
    var main = $(".menu-section--main");
    var trending = $(".menu-section--trends");
    var speciali = $(".menu-section--side");
    var search = $(".menu-section--search");

    var sum =
      (main.outerWidth(true)||0) +
      (speciali.outerWidth(true)||0) +
      (search.outerWidth(true)||0);
    var newWidth = Math.floor(menu.innerWidth() - sum);
    trending.css("maxWidth", newWidth);
  });
}

export function removeBrokenImages() {
  $(document).ready(function () {
    $("img").on("error", function() {
      $(this).remove();
    });
  });
}
