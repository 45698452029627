export default function timeRefresh() {
  let timeout;
  let timedOut;
  const target = 3e5;
  const qs = 'refresh_ce';
  function play() {
    timeout = setTimeout(() => {
      timedOut = true;
    }, target);
  }
  function stop() {
    if (timedOut) {
      let url = window.location.href;
      url += url.indexOf('?') === -1 ? '?' : '&';
      if (url.indexOf(qs) === -1) {
        window.location.replace(url + qs);
      } else {
        window.location.reload();
      }
    }
    clearTimeout(timeout);
    timedOut = false;
  }
  window.addEventListener('blur', play);
  window.addEventListener('focus', stop);
}
