// relative imports
import {
  initDropdowns,
  closeDropdowns,
  toggleHamburger,
  removeBrokenImages
} from "./init";

import {
  getCookie,
  isHome,
} from "./utility";

import timeRefresh from './time_refresh';

removeBrokenImages();
toggleHamburger();

initDropdowns();
closeDropdowns();

if (isHome()) {
  timeRefresh();
}

window.isDesktop = function(){ return true; };
window.isMobile = function(){ return false; };

if (typeof window.getCookie != "function") {
    window.getCookie = getCookie;
}
